import { useCallback, useEffect, useRef } from "react";
import { withStyles } from "tss-react/mui";
import { useSafeSetState } from "hooks/useState";
import {
  AutoComplete,
  Button,
  Confirmation,
  Loading,
  Input,
  Modal,
  MultiToggle,
  SmartInput,
} from "components";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { DeleteForever } from "@mui/icons-material";
import uuidv4 from "uuid/v4";
import timezones from "common/data/timezones.js";
import { silverColor, requiredFields } from "assets/jss/globalStyle";
import { SlatesGallery, CreateEditSlateView } from "../Slates/Slates";
import { createEditSlate, deleteSlate } from "services/slates";
import { fetchFeature } from "services/features";
import { NotificationObjI } from "types";
import { FeatureI } from "types/Feature";
import { CompanyI } from "types/Company";
import { useCompanyFeatureTemplates } from "hooks/featureTemplates";

// eslint-disable-next-line
const styles = (theme, props) => ({
  newFeature: {
    marginTop: "32px",
    width: "650px",
  },
  row: {
    width: "100%",
    margin: "0",
  },
  partialWidth: {
    width: "650px",
    margin: "0",
  },
  grouping: {
    width: "100%",
    border: `solid 3px ${silverColor}`,
    borderRadius: "5px",
    marginBottom: "16px",
  },
  groupingLabel: {
    fontSize: "15px",
    fontWeight: 600,
    color: "black",
    marginBottom: 0,
  },
  groupingParams: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  requiredFields,
  buttonRow: {
    width: "100%",
    margin: "16px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  trashButton: {
    fontSize: "42px",
  },
  note: {
    marginBottom: "32px",
    ">p": { margin: 0 },
  },
});

const initialState = {
  template: null,
  isActive: false,
  nameOverride: null,
  paramValues: { slates: null },
  hasRequiredFields: false,
  requiresCustomName: false,
  slates: null,
  isEditingSlate: false,
  selectedSlate: null,
  slatesConfirmation: false,
  savingSlates: false,
  pendingSlates: {
    // keep track of slates created or deleted. make api calls on save from featureModal
    create: [],
    delete: [],
    edit: [],
  },
};

interface FeaturesModalI {
  addNotification: (notification: NotificationObjI) => void;
  close: () => void;
  creatingNewFeature: boolean;
  deletingFeature?: FeatureI;
  features: FeatureI[];
  handleDelete: () => void;
  handleSave: (paramValues: any, template: any) => void;
  isOpen: boolean;
  prefetchedFeature?: FeatureI;
  requestingDelete: boolean;
  savingFeature: boolean;
  selectedCompany: CompanyI;
  selectedFeature?: any;
  selectedFeatureLoading: boolean;
  setFeatureDelete: (feature: FeatureI) => void;
  classes?: Partial<
    Record<
      | "newFeature"
      | "row"
      | "partialWidth"
      | "grouping"
      | "groupingLabel"
      | "groupingParams"
      | "requiredFields"
      | "buttonRow"
      | "trashButton"
      | "note",
      string
    >
  >;
}

function FeaturesModal(props: FeaturesModalI) {
  const {
    addNotification,
    close,
    creatingNewFeature,
    deletingFeature,
    features,
    handleDelete,
    handleSave,
    isOpen,
    prefetchedFeature,
    requestingDelete,
    savingFeature,
    selectedFeature,
    setFeatureDelete,
    selectedFeatureLoading,
    selectedCompany,
  } = props;
  const classes = withStyles.getClasses(props);

  const featureTemplates = useCompanyFeatureTemplates(selectedCompany?.id);

  const [
    {
      template,
      isActive,
      nameOverride,
      paramValues,
      hasRequiredFields,
      requiresCustomName,
      slates,
      isEditingSlate,
      selectedSlate,
      pendingSlates,
      slatesConfirmation,
      savingSlates,
    },
    safeSetState,
  ] = useSafeSetState(initialState);
  const latestParamValues = useRef(paramValues);
  const statusProperties = [
    { name: "On", value: isActive },
    { name: "Off", value: !isActive },
  ];
  const showModalClose =
    !deletingFeature && !slatesConfirmation && !isEditingSlate;
  const isSaving = savingSlates || savingFeature;

  const requiresCustomNameCheck = useCallback(
    (template) => {
      if (
        template.feature &&
        template.feature.requirements &&
        template.feature.requirements.mustOverrideName
      )
        return true;
      if (creatingNewFeature) {
        return features.some(
          (f) => f.featureID === template.id && !f.featureNameOverride
        );
      } else {
        return features.some(
          (f) =>
            f.featureID === selectedFeature?.featureID &&
            f.companyFeatureID !== selectedFeature?.id &&
            !f.featureNameOverride
        );
      }
    },
    [creatingNewFeature, features, selectedFeature]
  );

  const setFeatureTemplate = useCallback(
    (template) => {
      const params = template.parameters.reduce((a, c) => {
        if (c.defaultValue !== null) {
          if (c.options) {
            // map default values to option objects
            a[c.key] = c.options.find(
              (o) => o.value.toString() === c.defaultValue.toString()
            );
          } else if (c.typeName === "Timezone") {
            // for timezones, map default values to option objects from timezones hard data
            a[c.key] = c.defaultValue.map((val) =>
              timezones.find((tz) => tz.value === val)
            );
          } else {
            a[c.key] = c.defaultValue;
          }
        } else {
          a[c.key] = null;
        }
        return a;
      }, {});
      let nameOverride = null;
      let isActive = false;
      let slates = null;
      if (selectedFeature) {
        if (selectedFeature.active) isActive = true;
        if (selectedFeature.nameOverride)
          nameOverride = selectedFeature.nameOverride;
        const selectedParameters = selectedFeature.parameters;
        if (template.feature && template.feature.identifier === "carousel")
          slates = selectedParameters.slates;
        if (selectedParameters) {
          template.parameters.forEach((p) => {
            const val = selectedParameters[p.key];
            if (p.typeName === "Timezone") {
              if (selectedParameters[p.key] && selectedParameters[p.key].length)
                params[p.key] = selectedParameters[p.key].map((item) =>
                  // eslint-disable-next-line
                  timezones.find((tz) => tz.value == item)
                );
              // eslint-disable-next-line
            } else if (val != null) {
              if (p.options && p.options.length) {
                if (p.allowMultiples) {
                  const vals = [];
                  try {
                    val.forEach((v) => {
                      // eslint-disable-next-line
                      const foundVal = p.options.find((o) => o.value == v);
                      if (foundVal) vals.push(foundVal);
                    });
                    params[p.key] = vals;
                  } catch (err) {
                    console.error(
                      `Error parsing options/values for ${p.label}`,
                      err
                    );
                  }
                }
                // eslint-disable-next-line
                else params[p.key] = p.options.find((o) => o.value == val);
              } else params[p.key] = val;
            } else if (
              p.required &&
              p.valueGenerated &&
              p.valueGeneratorTypeName === "guid"
            ) {
              // If our param has no value but is required and is a guuid, then we generate it and prepopulate
              params[p.key] = uuidv4();
            }
          });
        } else {
          // Even if there are no selected parameters, we still want to prepopulate any required guid fields
          setGUIDs(template, params);
        }
      } else {
        // Even with a new feature, we want to set its guid by default if there is one
        setGUIDs(template, params);
      }
      // Check to see if any of the parameters are required
      const hasRequiredFields = template.parameters.some((p) => p.required);
      const requiresCustomName = requiresCustomNameCheck(template);
      safeSetState({
        template,
        paramValues: params,
        nameOverride,
        isActive,
        hasRequiredFields,
        requiresCustomName,
        slates,
      });
    },
    [requiresCustomNameCheck, safeSetState, selectedFeature]
  );

  useEffect(() => safeSetState(initialState), [isOpen, safeSetState]);

  useEffect(() => {
    latestParamValues.current = paramValues;
  }, [paramValues]);
  useEffect(() => {
    if (
      !selectedFeatureLoading &&
      selectedFeature &&
      featureTemplates?.data?.length &&
      !template
    ) {
      const template = featureTemplates.data.find(
        (t) => t.id === selectedFeature.featureID
      );
      setFeatureTemplate(template);
      safeSetState({ pendingSlates: { create: [], delete: [], edit: [] } });
    }
  }, [
    safeSetState,
    selectedFeature,
    selectedFeatureLoading,
    setFeatureTemplate,
    template,
    featureTemplates,
  ]);

  function canSave() {
    if (requiresCustomName && !nameOverride) return false;
    if (!template || !template.parameters || !template.parameters.length)
      return true;
    let featureCanBeSaved = true;
    template.parameters.forEach((p) => {
      if (p.required && !paramValues[p.key]) featureCanBeSaved = false;
    });
    // Feature level custom canSave calculation
    featureCanBeSaved = handleCustomSaveValidation(featureCanBeSaved);
    return featureCanBeSaved;
  }

  function handleCustomSaveValidation(canSave) {
    // additional custom f/e validation logic defined by feature identifier
    // default logic just passes through previously calculated canSave boolean
    const featureIdentifier = template.feature.identifier;
    let customCaveSave = true;
    switch (featureIdentifier) {
      case "provisional_traveler":
        customCaveSave = validateProvisionalTravelerFields();
        break;
    }
    // if custom validation shows error return new canSave otherwise, pass through existin canSave
    return !customCaveSave ? customCaveSave : canSave;
  }

  function validateProvisionalTravelerFields() {
    let canSave = true;
    // get grouped inputs
    // determine validation from there
    // if a provisional field paramaeter with grouping, get all param keys for Provisional Field Labels
    const groupedParamKeys = template.parameters
      .filter(
        (parameter) =>
          parameter.grouping?.indexOf("Provisional Field") > -1 &&
          parameter.label === "Field name"
      )
      .map((param) => param.key);

    // For each provisional field label param, check if any of the grouped Acme or Portal form dropdowns have Optional or Required selected (not Don't Show)
    canSave = groupedParamKeys.every((key) => {
      if (
        paramValues[`${key}Acme`].value != 0 ||
        paramValues[`${key}Portal`].value != 0
      ) {
        return Boolean(paramValues[key]);
      } else return true;
    });
    return canSave;
  }

  function handleClose() {
    const unsavedSlates = Object.keys(pendingSlates).some(
      (key) => pendingSlates[key].length > 0
    );
    if (unsavedSlates) {
      return safeSetState({ slatesConfirmation: true });
    }
    close();
  }

  function newFeatureDropdown() {
    return (
      <div className={classes.newFeature}>
        <AutoComplete
          errorMessage={
            featureTemplates.error
              ? "Error Fetching Feature Templates"
              : undefined
          }
          isLoading={featureTemplates.isLoading}
          placeholder={
            featureTemplates.isLoading
              ? "Loading Feature Templates"
              : "Select Feature To Add"
          }
          onChange={setFeatureTemplate}
          suggestions={featureTemplates.data}
          autoRenderSuggestions
          fixedPositionWidth="650px"
          dataAut="Features|AddFeature"
        />
      </div>
    );
  }

  function setGUIDs(template, params) {
    template.parameters.forEach((p) => {
      if (
        p.required &&
        p.valueGenerated &&
        p.valueGeneratorTypeName === "guid"
      ) {
        params[p.key] = uuidv4();
      }
    });
  }

  function renderNote(param, i) {
    return (
      <div className={classes.note} key={`note-${i}`}>
        <p style={{ fontWeight: "bold" }}>{param.label}</p>
        <p>{param.secondaryLabel}</p>
      </div>
    );
  }

  function renderParams(params) {
    const groupedParamKeys = [];
    // loop through each param
    // if it's a solo param, map it
    // if it's a grouped param, check if grouping exists, if it already does, pass it.
    // if param is first item of group, render the grouping together
    const mappedParams = params.map((p, i) => {
      if (p.grouping) {
        if (groupedParamKeys.includes(p.grouping)) return;
        // track groups that we've rendered
        groupedParamKeys.push(p.grouping);
        // get all the param objects for this current group
        const currentGrouping = params.filter(
          (param) => param.grouping === p.grouping
        );
        return (
          <fieldset className={classes.grouping} key={`grouping-${i}`}>
            <legend className={classes.groupingLabel}>{p.grouping}:</legend>
            <div className={classes.groupingParams}>
              {currentGrouping.map(mapParams)}
            </div>
          </fieldset>
        );
      } else {
        return mapParams(p, i);
      }
    });
    return mappedParams;
  }

  function removeValue(key, val) {
    const newParamValues = { ...paramValues };
    newParamValues[key] = newParamValues[key].filter((p) => p !== val);
    safeSetState({ paramValues: newParamValues });
  }

  function setValue(val, p) {
    const newParams = { ...paramValues };
    newParams[p.key] = val;
    if (p.typeName === "File" && template.name === "File") {
      // Only update the name overwrite if in a File feature
      let nameOverride = null;
      if (val && val.name && val.name.length)
        nameOverride = val.name.split(".").slice(0, -1).join(".");
      safeSetState({ paramValues: newParams, nameOverride });
    } else if (p.typeName === "Html") {
      safeSetState({
        paramValues: { ...latestParamValues.current, [p.key]: val },
      });
    } else safeSetState({ paramValues: newParams });
  }

  function mapParams(p, i) {
    if (p.key === "slates") {
      return (
        <SlatesGallery
          handleOpenCreateEditSlate={handleOpenCreateEditSlate}
          trackPendingSlate={trackPendingSlate}
          setSlates={setSlates}
          slates={slates}
          key={i}
        />
      );
    } else if (p.typeName === "Note") {
      return renderNote(p, i);
    } else {
      return (
        <SmartInput
          key={`${p.key}-${i}`}
          templateParameter={p}
          paramValues={paramValues}
          removeValue={(val) => removeValue(p.key, val)}
          setValue={(val) => setValue(val, p)}
        />
      );
    }
  }

  function featureTemplate() {
    const params = template.parameters.filter(
      (p) => p.key !== "Position" && p.key !== "Width"
    );
    const soloParams = [];
    const groupings = {};
    params.forEach((p) => {
      if (p.grouping) {
        if (groupings[p.grouping]) groupings[p.grouping].push(p);
        else groupings[p.grouping] = [p];
      } else {
        soloParams.push(p);
      }
    });
    return (
      <form autoComplete="off">
        <div className={classes.partialWidth}>
          <Input
            label={`Custom Name${requiresCustomName ? " *" : ""}`}
            value={nameOverride || ""}
            onChange={(nameOverride) => safeSetState({ nameOverride })}
            data-aut="FeaturesModal|CustomNameInput"
          />
        </div>
        {renderParams(params)}
        <div className={classes.buttonRow}>
          <MultiToggle
            value={isActive ? 0 : 1}
            properties={statusProperties}
            handleClick={() => safeSetState({ isActive: !isActive })}
            label="Active"
          />
        </div>
        <div className={classes.buttonRow}>
          <span>
            <Button
              onClick={handleSaveButtonClick}
              label="Save"
              loading={isSaving}
              disabled={isSaving || !canSave()}
              data-aut="Features|SaveButton"
            />
            <Button
              onClick={handleClose}
              label="Cancel"
              isTextButton
              data-aut="Features|CancelButton"
            />
          </span>
          {prefetchedFeature && (
            <Tooltip title="Delete Feature" placement={"top"} enterDelay={150}>
              <IconButton
                onClick={() => setFeatureDelete(prefetchedFeature)}
                data-aut="Features|DeleteButton"
                size="large"
              >
                <DeleteForever className={classes.trashButton} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </form>
    );
  }

  function newFeature() {
    if (template) return featureTemplate();
    else return newFeatureDropdown();
  }

  function editingFeature() {
    if (!selectedFeature || !template) {
      return (
        <Loading active loadingText="Loading Selected Feature." addContainer />
      );
    } else return featureTemplate();
  }

  function renderDeleteConfirmation() {
    return (
      <Confirmation
        handleConfirmation={handleDelete}
        handleClose={handleClose}
        header={`Are you sure you want to delete the ${
          deletingFeature.featureNameOverride || deletingFeature.featureName
        } feature?`}
        requestingConfirmation={requestingDelete}
      />
    );
  }

  function renderSlatesConfirmation() {
    return (
      <Confirmation
        header={`You have unsaved carousel slate changes. Are you sure you want to leave without saving?`}
        handleClose={() => safeSetState({ slatesConfirmation: false })}
        handleConfirmation={close}
        buttonLabel={"Yes"}
      />
    );
  }

  function renderTableBody() {
    if (!isOpen) return;
    if (deletingFeature) return renderDeleteConfirmation();
    if (slatesConfirmation) return renderSlatesConfirmation();
    if (isEditingSlate) return renderSlateEditView();
    else if (creatingNewFeature) return newFeature();
    else return editingFeature();
  }

  function renderHeader() {
    if (deletingFeature || slatesConfirmation) return;
    else if (isEditingSlate)
      return selectedSlate.slateId ? "Edit Slate" : "Create Slate";
    else if (template && template.label) {
      if (hasRequiredFields || requiresCustomName) {
        return (
          <span data-aut="FeatureModal|Heading">
            {template.label}{" "}
            <span className={classes.requiredFields}>* Required Fields</span>{" "}
          </span>
        );
      } else {
        return <span data-aut="FeaturesModal|Heading">{template.label}</span>;
      }
    } else if (!prefetchedFeature) return "Add a new feature";
  }

  function renderSlateEditView() {
    return (
      <CreateEditSlateView
        handleCloseCreateEditSlate={handleCloseCreateEditSlate}
        trackPendingSlate={trackPendingSlate}
        addNotification={addNotification}
        selectedSlate={selectedSlate}
      />
    );
  }

  function handleOpenCreateEditSlate(slate) {
    safeSetState({ isEditingSlate: true, selectedSlate: slate });
  }

  function handleCloseCreateEditSlate() {
    safeSetState({
      isEditingSlate: false,
      selectedSlate: null,
    });
  }

  function setSlates(slates) {
    safeSetState({ slates });
  }

  function trackPendingSlate({ action, slate }) {
    // keep track of slates to be created or deleted upon feature save
    const newPendingSlates = { ...pendingSlates };
    const pendingSlate = { ...slate };

    switch (action) {
      case "create": {
        // is this slate already pending?
        const pendingCreateSlates = pendingSlates.create;
        const pendingIdx = pendingCreateSlates.findIndex(
          (s) => s.pendingCreateId === slate.pendingCreateId
        );
        if (pendingIdx > -1) {
          // already exists in pending slates, replace it
          pendingCreateSlates[pendingIdx] = pendingSlate;
          const slateIdx = slates.findIndex(
            (s) => s.pendingCreateId === pendingSlate.pendingCreateId
          );
          const newSlatesState = [...slates];
          newSlatesState[slateIdx] = pendingSlate;
          setSlates(newSlatesState);
        } else {
          // newly created slate, create an id and add to list
          const pendingCreateId = uuidv4();
          pendingSlate.pendingCreateId = pendingCreateId;
          newPendingSlates.create.push(pendingSlate);
          const newSlates =
            slates && slates.length
              ? [...slates, pendingSlate]
              : [pendingSlate];
          setSlates(newSlates);
        }
        break;
      }
      case "delete": {
        newPendingSlates.delete.push(pendingSlate);
        break;
      }
      case "edit": {
        // is this slate already pending edit?
        const pendingEditSlates = pendingSlates.edit;
        const pendingEditIdx = pendingEditSlates.findIndex(
          (s) => s.slateId === slate.slateId
        );
        if (pendingEditIdx > -1) {
          pendingEditSlates[pendingEditIdx] = pendingSlate;
        } else {
          pendingSlates.edit.push(pendingSlate);
        }
        const slateIdx = slates.findIndex(
          (s) => s.slateId === pendingSlate.slateId
        );
        const newSlatesState = [...slates];
        newSlatesState[slateIdx] = pendingSlate;
        setSlates(newSlatesState);
        break;
      }
      default:
        break;
    }
    safeSetState({ pendingSlates: newPendingSlates });
  }

  async function handleCreateSlate(slateParams, newSlates) {
    const createResponse = await createEditSlate(
      slateParams,
      selectedCompany.id
    );
    // finished saving, add slate ids to slates on component state to track position
    const createSlateIdx = newSlates.findIndex(
      (s) => s.pendingCreateId === slateParams.pendingCreateId
    );
    if (createSlateIdx > -1) {
      newSlates[createSlateIdx] = createResponse;
    }
  }

  async function handleDeleteSlate(slateId, newSlates) {
    await deleteSlate(slateId);
    // finished deleting, remove slate from component state
    const deletedSlateIdx = newSlates.findIndex((s) => s.slateId === slateId);
    if (deletedSlateIdx > -1) {
      newSlates.splice(deletedSlateIdx, 1);
    }
  }

  async function handleSaveSlateChanges() {
    // handle create and delete slates
    const promises = [];
    const newSlates = [...slates];
    safeSetState({ savingSlates: true });

    try {
      pendingSlates.create.forEach((s) =>
        promises.push(handleCreateSlate(s, newSlates))
      );
      pendingSlates.delete.forEach((s) =>
        promises.push(handleDeleteSlate(s.slateId, newSlates))
      );
      pendingSlates.edit.forEach((s) =>
        promises.push(createEditSlate(s, selectedCompany.id))
      );
      await Promise.all(promises);
      // finished saving slate changes, get order of slates to send to feature save
      const slateOrder = newSlates.map((s) => s.slateId);
      paramValues.slates = slateOrder;

      // save feature
      handleSave(
        {
          parameters: { ...paramValues },
          active: isActive,
          nameOverride,
          featureID: template.id,
        },
        template
      );
    } catch (e) {
      // if any slate requests fail and throw and err, show notification and refetch slates
      addNotification({
        text: `Error saving one or more slates.`,
        type: "error",
      });
      const featurePayload = await fetchFeature(selectedFeature.id);
      const slates = featurePayload.parameters.slates;
      safeSetState({ slates, savingSlates: false });
    } finally {
      safeSetState({
        pendingSlates: { create: [], delete: [], edit: [] },
      });
    }
  }

  function handleSaveButtonClick() {
    if (slates) {
      handleSaveSlateChanges();
    } else {
      handleSave(
        {
          parameters: { ...paramValues },
          active: isActive,
          nameOverride,
          featureID: template.id,
        },
        template
      );
    }
  }

  return (
    <Modal
      close={showModalClose && handleClose}
      isOpen={isOpen}
      header={renderHeader()}
      disableEnforceFocus={true}
    >
      {renderTableBody()}
    </Modal>
  );
}

export default withStyles(FeaturesModal, styles);
