import React, { useEffect, useId, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "tss-react/mui";
import { AutoComplete, Dropdown, HtmlEditor, Input } from "components";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import timezones from "common/data/timezones.js";
import uuidv4 from "uuid/v4";
import styles from "components/Sidebar/smartInputStyle";
import UploadInput from "../UploadInput/UploadInput";
import ColorPicker from "../ColorPicker/ColorPicker";
import cx from "classnames";

/*
  Helper Functions
*/

function multiAutoAdd(setValue, selectedValue, value) {
  const newValue = [...selectedValue];
  newValue.push(value);
  setValue(newValue);
}

function getPrimaryLabel({ templateParameter }) {
  return `${templateParameter.label}${templateParameter.required ? " *" : ""}`;
}

function renderSecondaryLabel({ label, classes }) {
  if (!label) return;
  return <p className={classes.secondaryText}>{label}</p>;
}

/*
  Text Inputs
*/

function IntegratedInput(props) {
  if (props.templateParameter.allowMultiples) return MultiInput(props);
  else return SingleInput(props);
}

function MultiInput({
  classes,
  templateParameter,
  paramValues,
  secondaryLabel,
  setValue,
}) {
  let selectedValue;
  if (paramValues[templateParameter.key])
    selectedValue = paramValues[templateParameter.key];
  else selectedValue = [];
  const [inputValue, setInputValue] = useState("");

  return (
    <div
      className={cx(classes.fullWidth, classes.inputContainer)}
      style={secondaryLabel ? { marginBottom: "24px" } : {}}
    >
      <Input
        label={getPrimaryLabel({ templateParameter })}
        value={inputValue}
        onChange={setInputValue}
        selectedItems={selectedValue}
        setValues={setValue}
        isMultiple
      />
      {secondaryLabel}
    </div>
  );
}

function ColorInput({ classes, templateParameter, paramValues, setValue }) {
  const selectedValue = paramValues[templateParameter.key];
  return (
    <div className={cx(classes.partialWidth, classes.inputContainer)}>
      <div style={{ display: "flex" }}>
        <ColorPicker
          value={selectedValue}
          onChange={setValue}
          defaultValue={templateParameter.defaultValue}
          inputLabel={getPrimaryLabel({ templateParameter })}
        />
      </div>
    </div>
  );
}

function SingleInput({
  classes,
  templateParameter,
  paramValues,
  secondaryLabel,
  setValue,
}) {
  const isGUID =
    templateParameter.valueGenerated &&
    templateParameter.valueGeneratorTypeName === "guid";
  let selectedValue;
  if (paramValues[templateParameter.key])
    selectedValue = paramValues[templateParameter.key];
  else selectedValue = "";
  return (
    <div className={cx(classes.partialWidth, classes.inputContainer)}>
      <Input
        label={getPrimaryLabel({ templateParameter })}
        value={selectedValue}
        onChange={setValue}
        disabled={isGUID || templateParameter.typeName === "Readonly"}
        placeholder={templateParameter.placeholder}
      />
      {isGUID && (
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => setValue(uuidv4())}
        >
          Regenerate
        </Button>
      )}
      {secondaryLabel}
    </div>
  );
}

/*
  Dropdown selects
*/

function IntegratedDropdown(props) {
  if (props.templateParameter.allowMultiples) return MultiDropdown(props);
  else return SingleDropdown(props);
}

function SingleDropdown({
  classes,
  options,
  paramValues,
  templateParameter,
  secondaryLabel,
  setValue,
}) {
  const selectedValue = paramValues[templateParameter.key];

  return (
    <div className={cx(classes.partialWidth, classes.inputContainer)}>
      <Dropdown
        displayEmpty
        id={templateParameter.label}
        handleDelete={(val) => setValue(selectedValue.filter((v) => v !== val))}
        label={getPrimaryLabel({ templateParameter })}
        onChange={setValue}
        selectedItems={selectedValue || ""}
        items={options}
      />
      {secondaryLabel}
    </div>
  );
}

function MultiDropdown({
  classes,
  options,
  paramValues,
  removeValue,
  templateParameter,
  secondaryLabel,
  setValue,
}) {
  let selectedValue = [];
  if (paramValues[templateParameter.key])
    selectedValue = paramValues[templateParameter.key];
  const isDisabled = Boolean(!options || !options.length);
  return (
    <div className={cx(classes.fullWidth, classes.inputContainer)}>
      <Dropdown
        multiple
        id={templateParameter.label}
        handleDelete={removeValue}
        label={getPrimaryLabel({ templateParameter })}
        onChange={setValue}
        selectedItems={selectedValue}
        items={options}
        disabled={isDisabled}
        displayEmpty={isDisabled}
        placeholder={"No Options Provided"}
      />
      {secondaryLabel}
    </div>
  );
}

/*
  Autocomplete search selects
*/

function IntegratedAutoComplete(props) {
  if (props.templateParameter.allowMultiples) return MultiAutoComplete(props);
  else return SingleAutoComplete(props);
}

function SingleAutoComplete({
  classes,
  options,
  paramValues,
  templateParameter,
  secondaryLabel,
  setValue,
}) {
  const selectedValue = paramValues[templateParameter.key];

  return (
    <div className={cx(classes.inputContainer, classes.partialWidth)}>
      <AutoComplete
        label={getPrimaryLabel({ templateParameter })}
        handleDelete={() => setValue(null)}
        onChange={setValue}
        selectedItem={selectedValue}
        suggestions={options}
        fixedPositionWidth="650px"
        autoRenderSuggestions
      />
      {secondaryLabel}
    </div>
  );
}

function MultiAutoComplete({
  classes,
  options,
  paramValues,
  removeValue,
  templateParameter,
  secondaryLabel,
  setValue,
}) {
  let selectedValue = [];
  if (paramValues[templateParameter.key])
    selectedValue = paramValues[templateParameter.key];
  return (
    <div className={cx(classes.inputContainer, classes.fullWidth)}>
      <AutoComplete
        label={getPrimaryLabel({ templateParameter })}
        autoRenderSuggestions
        isMulti
        handleDelete={removeValue}
        onChange={(val) => multiAutoAdd(setValue, selectedValue, val)}
        selectedItem={selectedValue}
        suggestions={options}
        maxResults={100}
        fixedPositionWidth="800px"
      />
      {secondaryLabel}
    </div>
  );
}

// Leaving radio/checkbox group UI options to revisit later

function IntegratedRadioGroup({
  classes,
  options,
  paramValues,
  templateParameter,
  setValue,
}) {
  const handleChange = (e) => {
    const optionValue = e.target.value;
    const optionObj = options.find((o) => o.value === optionValue);
    setValue(optionObj);
  };
  const radioValue =
    paramValues[templateParameter.key] &&
    paramValues[templateParameter.key].value;

  useEffect(() => {
    if (radioValue) return;
    if (templateParameter.required && templateParameter.defaultValue != null) {
      const val = templateParameter.options.find(
        (option) => option.value == templateParameter.defaultValue
      );
      if (val) {
        setValue(val);
      }
    }
  }, [
    radioValue,
    setValue,
    templateParameter.defaultValue,
    templateParameter.options,
    templateParameter.required,
  ]);

  const getRadioValue = () => {
    return radioValue || "";
  };
  const groupId = useId();

  return (
    <div className={classes.fullWidth}>
      <FormControl>
        <FormLabel className={classes.label} id={groupId}>
          {getPrimaryLabel({ templateParameter })}
        </FormLabel>
        <RadioGroup
          aria-labelledby={groupId}
          name={templateParameter.label}
          value={getRadioValue()}
          row
          onChange={handleChange}
        >
          {options.map((option, idx) => {
            const id = `${groupId}-option-${idx}-${option.label}`;
            return (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio color="primary" inputProps={{ id }} />}
                label={option.label}
                htmlFor={id}
                classes={{
                  label: classes.blackText,
                }}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

// function IntegratedCheckboxGroup({
//   classes,
//   options,
//   paramValues,
//   templateParameter,
//   setValue,
// }) {
//   const templateKey = templateParameter.key;
//   const params = paramValues[templateKey] ? [...paramValues[templateKey]] : [];

//   const isChecked = (option) => {
//     return Boolean(params.find((item) => item.value === option.value));
//   };
//   const handleChecked = (e) => {
//     const val = e.target.value;
//     const option = options.find((o) => o.value === val);
//     const itemIdx = params.findIndex((param) => param.value === val);
//     const isUnselecting = isChecked(option);

//     isUnselecting ? params.splice(itemIdx, 1) : params.push(option);
//     setValue(params);
//   };

//   return (
//     <div className={classes.fullWidth}>
//       <FormControl>
//         <FormLabel className={classes.label}>
//           {getPrimaryLabel({ templateParameter })}
//         </FormLabel>
//         {options.map((option) => (
//           <FormControlLabel
//             key={option.value}
//             value={option.value}
//             label={option.label}
//             control={
//               <Checkbox
//                 type="checkbox"
//                 color="primary"
//                 name={option.label}
//                 value={option.value}
//                 checked={isChecked(option)}
//                 onChange={handleChecked}
//               />
//             }
//             classes={{
//               label: classes.blackText,
//             }}
//           />
//         ))}
//       </FormControl>
//     </div>
//   );
// }

function IntegratedUpload({
  classes,
  templateParameter,
  paramValues,
  secondaryLabel,
  setValue,
}) {
  let selectedValue;
  if (paramValues[templateParameter.key])
    selectedValue = paramValues[templateParameter.key];
  return (
    <div className={cx(classes.inputContainer, classes.fullWidth)}>
      <UploadInput
        label={getPrimaryLabel({ templateParameter })}
        value={selectedValue}
        onChange={setValue}
      />
      {secondaryLabel}
    </div>
  );
}

//

/*
  Boolean Toggles
*/
function CheckboxToggle({ classes, paramValues, setValue, templateParameter }) {
  const active =
    paramValues[templateParameter.key] == null
      ? Boolean(templateParameter.defaultValue)
      : paramValues[templateParameter.key];
  return (
    <div className={classes.inputContainer}>
      <Checkbox
        classes={{ root: classes.checkbox }}
        checked={active}
        color="primary"
        onChange={() => setValue(!active)}
        value={templateParameter.label}
        inputProps={{ "aria-label": templateParameter.label }}
      />
      <label className={classes.label}>{templateParameter.label}</label>
    </div>
  );
}

// Leaving this here in case we ever want it again
// const BooleanToggle = (props) => {
//   const { classes, paramValues, templateParameter } = props;
//   let active = false;
//   let statusProperties = [
//     { name: 'True', value: false }, { name: 'False', value: false }
//   ];
//   if (paramValues[templateParameter.key] || templateParameter.defaultValue) active = true;
//   if (active) {
//     statusProperties[0].value = true;
//   } else {
//     statusProperties[1].value = true;
//   }
//   return (
//     <div className={classes.partialWidth}>
//       <label className={classes.label}>{templateParameter.label}</label>
//       <MultiToggle
//         value={active ? 0 : 1}
//         properties={statusProperties}
//         handleClick={this.statusClick}
//         label={templateParameter.label}
//       />
//     </div>
//   )
// };

function HtmlInput({ classes, paramValues, setValue, templateParameter }) {
  function handleTemplateUpdate(evt) {
    const newValue = evt.editor.getData();
    if (typeof newValue === "string") {
      // Due to the fact that we are not escaping events, ckeditor is nice enough to allow enter keys to show up in the template. We remove them here.
      const sanitizedValue = newValue.replace(/↵/g, "");
      setValue(sanitizedValue);
    }
  }
  let selectedValue;
  if (paramValues[templateParameter.key])
    selectedValue = paramValues[templateParameter.key];

  return (
    <div
      className={cx(classes.inputContainer, classes.fullWidth)}
      style={{ marginBottom: "36px", minHeight: "340px" }}
    >
      <label className={classes.label}>{templateParameter.label}</label>
      <HtmlEditor content={selectedValue} handleChange={handleTemplateUpdate} />
    </div>
  );
}

function SmartInput(props) {
  const { templateParameter } = props;
  const classes = withStyles.getClasses(props);
  const copiedProps = { ...props };
  copiedProps.secondaryLabel = renderSecondaryLabel({
    label: templateParameter.secondaryLabel,
    classes,
  });
  if (templateParameter.typeName === "Color") return ColorInput(copiedProps);
  if (templateParameter.typeName === "Html") return HtmlInput(copiedProps);
  if (templateParameter.typeName === "File")
    return IntegratedUpload(copiedProps);
  if (templateParameter.typeName === "Boolean")
    return CheckboxToggle(copiedProps);
  let options;
  if (templateParameter.options) options = templateParameter.options;
  else if (templateParameter.typeName === "Timezone") options = timezones;
  if (!options) return IntegratedInput(copiedProps);
  else if (templateParameter.typeName === "Radio")
    return IntegratedRadioGroup({ ...copiedProps, options });
  else if (options.length < 15)
    return IntegratedDropdown({ ...copiedProps, options });
  else return IntegratedAutoComplete({ ...copiedProps, options });
}

SmartInput.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array,
  paramValues: PropTypes.object.isRequired,
  removeValue: PropTypes.func,
  templateParameter: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
};

const SmartInputStyled = withStyles(SmartInput, styles);

export default SmartInputStyled;
