import { withStyles } from "tss-react/mui";
import { TableCell, TableRow } from "@mui/material";
import { Error } from "@mui/icons-material";
import CheckboxSpinner from "components/CheckboxSpinner/CheckboxSpinner";
import companyRoleFeatureStyle from "components/CompanyRoleFeatures/companyRoleFeatureStyle";
import { FeatureRoleI, RoleI } from "types/Role";
import { FeatureI } from "types/Feature";

function isRoleActive(role: FeatureRoleI, roles: RoleI[]) {
  const roleData = roles.find((r) => r.id === role.id);
  return Boolean(roleData && roleData.active);
}

interface CompanyRoleFeatureI {
  hasApproveRole: boolean;
  feature: FeatureI;
  featureIndex: number;
  roles: RoleI[];
  selectFeature: (feature: FeatureI) => void;
  toggle: (feature: FeatureI, roleIndex: number) => void;
  classes?: Partial<
    Record<
      | "propertyCell"
      | "cellContainer"
      | "error"
      | "featureRow"
      | "tableCell"
      | "tableCellApprove"
      | "flexVertAligned"
      | "popupIcon"
      | "popover"
      | "paper"
      | "popoverText",
      string
    >
  >;
}

const CompanyRoleFeature = (props: CompanyRoleFeatureI) => {
  const {
    hasApproveRole,
    feature,
    featureIndex,
    roles,
    selectFeature,
    toggle,
  } = props;
  const classes = withStyles.getClasses(props);

  return (
    <TableRow
      key={`${feature.featureName}${featureIndex}`}
      className={classes.featureRow}
      hover
    >
      <TableCell
        className={classes.propertyCell}
        onClick={() => selectFeature(feature)}
      >
        {feature.featureName}
      </TableCell>
      <TableCell
        className={classes.propertyCell}
        style={{ left: 240 }}
        onClick={() => selectFeature(feature)}
      >
        {feature.featureNameOverride}
      </TableCell>
      {feature.roles.map((role, roleIndex) => {
        const checkboxColor =
          [
            "Blue",
            "Pink",
            "Green",
            "Purple",
            "Blue",
            "Pink",
            "Green",
            "Purple",
            "Blue",
            "Pink",
            "Green",
            "Purple",
            "Blue",
            "Pink",
            "Green",
            "Purple",
          ][roleIndex] || "Blue";
        const checkboxClass = `checkbox${checkboxColor}`;
        const isActive = isRoleActive(role, roles);
        return (
          <TableCell
            className={
              hasApproveRole ? classes.tableCellApprove : classes.tableCell
            }
            key={roleIndex}
          >
            <div className={classes.cellContainer}>
              <CheckboxSpinner
                enabled={role.enabled}
                disabled={!isActive || role.loading || role.disabled}
                loading={role.loading}
                onChange={() => toggle(feature, roleIndex)}
                checkboxClass={checkboxClass}
                value={`${feature.featureName}: ${role.name}`}
              />
              {role.error && <Error className={classes.error} />}
            </div>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default withStyles(CompanyRoleFeature, companyRoleFeatureStyle);
