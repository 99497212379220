import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  createCompany,
  deleteCompany,
  editCompany,
  fetchCompanies,
  getActiveApproveDebtors,
} from "services/companies";
import { useAddNotification } from "./useAddNotification";
import { SET_SELECTED_COMPANY } from "redux/actions/selectedCompanyID";
import {
  QUERYKEY_COMPANIES,
  QUERYKEY_COMPANY_APPROVE_DEBTORS,
  QUERYKEY_SELECTED_COMPANY,
} from "./queryKeys";
import { COMPANIES } from "common/constants";

const mapCompanies = (companies = []) => {
  return companies.map((data) => {
    const trimmedTitle = (data.title || "").trim();
    return {
      ...data,
      modifiedOn: moment(data.modifiedOn),
      title: trimmedTitle,
      label: trimmedTitle,
    };
  });
};

export function useCompanies() {
  return useQuery({
    queryKey: [QUERYKEY_COMPANIES],
    queryFn: fetchCompanies,
    select: mapCompanies,
  });
}

export function useCreateCompany() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createCompany,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_COMPANIES] }),
  });
}

export function useEditCompany(id) {
  const queryClient = useQueryClient();
  const addNotification = useAddNotification();
  return useMutation({
    mutationFn: editCompany,
    onSuccess: () => {
      addNotification({
        text: `Company successfully saved.`,
        type: "success",
      });
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_SELECTED_COMPANY, id],
        }),
        queryClient.invalidateQueries({
          queryKey: [QUERYKEY_COMPANIES],
        }),
      ]);
    },
    onError: (e: any) => {
      addNotification({
        text: `There was an error saving the company.`,
        type: "error",
        errors: e.customErrors,
      });
    },
  });
}

export function useDeleteCompany() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const addNotification = useAddNotification();
  return useMutation({
    mutationFn: deleteCompany,
    onSuccess: () => {
      dispatch({
        type: SET_SELECTED_COMPANY,
        id: null,
      });
      addNotification({
        text: `Company successfully deleted.`,
        type: "success",
      });
      return queryClient.invalidateQueries({ queryKey: [COMPANIES] });
    },
    onError: () => {
      addNotification({
        text: `There was an error deleting the company.`,
        type: "error",
      });
    },
  });
}

export function useEditCompanyEmailTrigger(id) {
  const queryClient = useQueryClient();
  const addNotification = useAddNotification();
  return useMutation({
    mutationFn: editCompany,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [QUERYKEY_SELECTED_COMPANY, id],
      });
    },
    onError: () => {
      addNotification({
        text: "Error toggling auto email trigger for selected company.",
        type: "error",
      });
    },
  });
}

export function useApproveEnabledDebtors(id) {
  return useQuery({
    queryKey: [QUERYKEY_COMPANY_APPROVE_DEBTORS, id],
    queryFn: () => getActiveApproveDebtors(id),
    select: (payload) => {
      return {
        ...payload,
        debtors: payload.debtors.map((d) => ({
          ...d,
          value: d.id,
          label: d.name,
        })),
      };
    },
    enabled: Boolean(id),
  });
}
